@import "assets/styles/variables.scss";

.table {
  margin-bottom: 1rem;

  & th,
  & td {
    font-size: 1.3rem;
  }

  & th {
    div {
      color: $color_secondary_text !important;
      cursor: default !important;
    }

    &:nth-child(2) {
      width: 150px;
    }
  }

  & th div,
  & tr td {
    padding: 1.6rem;
    line-height: 1.231;

    ul {
      display: grid;
      grid-template-columns: 150px 1fr;
      gap: 2rem 0;
    }
  }

  & tr td {
    &:nth-child(1) {
      width: 100px;
    }

    &:nth-child(2) {
      max-width: 500px;
    }

    &:nth-child(3) {
      width: 250px;
    }

    &:nth-child(4) {
      width: 250px;
    }

    li {
      word-break: break-word;
    }
  }

  & tfoot {
    td {
      font-weight: 500;
      &:last-child {
        text-align: right;
      }

      & li {
        color: $color_secondary_text;
      }
    }
  }
}
