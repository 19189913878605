@import "assets/styles/variables.scss";

.table {
  width: 100%;
  overflow: hidden;
  color: $color_secondary_text;
}

.tableHeader,
.tableData {
  font-size: 1.2rem;
}

.tableHeader {
  position: relative;
  text-align: left;
  font-weight: 500;
  transition: $transition_speed;
}

.tableHeaderWrapper {
  display: flex;
  gap: 0.5rem;
  margin: 0 0.2rem;
  border-bottom: 0.1rem solid $color_secondary_gray_light;

  &.active,
  &:hover {
    color: $color_primary;

    .sort {
      color: $color_primary;
    }
  }
}

.tableHeaderWrapper, .tableData {
  padding: 1.5rem 0;
}

tbody .tableRow {
  transition: $transition_speed;

  &.disabled {
    opacity: 0.5;
  }

  &.warning {
    background-color: $color_pink;
  }

  &.error {
    color: $color_primary;
  }

  &:hover {
    background-color: $color_white;
  }
}

tfoot {
  background-color: $color_secondary_gray_light_0_4;
}

.sort {
  transition: $transition_speed;
  max-width: 1.2rem;
  color: $color_secondary_gray_light;

  &.desc {
    transform: rotate(180deg);
  }
}

.empty td {
  padding: 4rem;
  text-align: center;
  color: $color_secondary_gray_light;
}

.warningTitle {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.warningDescription {
  font-size: 1.8rem;
}

.pagination {
  width: max-content;
  margin-top: 2rem;
  margin-left: auto;
  margin-bottom: 2rem;
}
