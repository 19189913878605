@import "assets/styles/variables.scss";
@import "assets/styles/mixins.scss";

.controls {
  z-index: 3;
  :global {
    .controls__additional-controls {
      display: flex;
      align-items: flex-start;
      gap: 4rem;
    }

    .select {
      width: max-content;
      font-size: 1.6rem;

      & {
        color: $color_primary;
        transform: translate(0, -2px);

        .filter-select {
          width: auto;
          height: auto;

          &__multi-value__label {
            color: $color_primary;
          }

          &__placeholder {
            padding-left: 0;
            color: $color_primary;
          }

          &__control--is-focused {
            outline: none !important;
          }

          &__dropdown-indicator {
            path {
              fill: $color_primary;
            }
          }

          &__multi-value {
            background-color: transparent;
            padding-left: 0;
          }

          &__value-container {
            padding-left: 0;
            flex-wrap: wrap;
          }
        }

        .filter-select__control {
          cursor: pointer;
        }
      }
    }
  }
}

.wrapper {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;

  :global {
    .expansion-summary {
      &__control {
        min-height: 4.8rem;
        font-size: 1.4rem;
        line-height: 2.1rem;
        padding: 0 1.6rem;
        border-top: 1px solid #bbbdc0;
        background-color: rgba(187, 189, 192, 0.15);
      }

      &__label {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
}

.buttonGroup {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: auto;

  & button:last-child {
    margin-left: 1rem;
  }
}

.toggleButton {
  @include button();
  @include primary-button();
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.8rem;
  height: 3.8rem;
  cursor: pointer;
  background-color: $primary_button_disable_color;
  transition: $transition_speed;

  & path {
    fill: $header_optional_text_color;
  }

  &:hover {
    background-color: $primary_button_disable_color;
  }

  &.active {
    background-color: $color-primary;

    & path {
      fill: $color_white;
    }
  }
}

.excelButton {
  @include button();
  @include primary-button();
  width: 11.1rem;
  height: 4rem;

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 1;
  }
}

.footer {
  background-color: $color_secondary_gray_light_0_4;
  padding: 1.5rem 2rem;
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 500;
  color: $color_secondary_text;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.tableControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.sort {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  color: $color-primary;
  font-size: 1.3rem;
  font-weight: 500;

  &.Employee {
    &.desc {
      & svg {
        transform: rotate(180deg);
      }
    }
  }

  &.Date {
    &.asc {
      & svg {
        transform: rotate(180deg);
      }
    }
  }

  & svg {
    transition: 0.3s;
  }
}

.groupControl {
  font-size: 13px;
}
