@import "assets/styles/variables.scss";

$border-radius: 5px;

.popper {
  font-family: Etelka, Arial, sans-serif;
  /* stylelint-disable */
  :global {
    .react-datepicker {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &__month-container {
        width: 100%;
      }

      &__month {
        margin: 0 0 10px;
      }

      &__day-names {
        padding: 14px 14px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
      }

      &__header {
        background-color: $color-white;
        border-bottom: none;
        padding-bottom: 0;

        & > div:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0.7rem;

          & div {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
          }
        }

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
          font-size: 14px;
          color: $color_black;
        }
      }

      &__day-name {
        width: 3rem;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $color_secondary_gray_light;
      }

      &__current-month {
        font-weight: normal;
      }

      &__day,
      &__time-name {
        padding: 5px 8px;
        margin: 3px 0;
      }

      &__day {
        &:not(.react-datepicker__day--disabled) {
          color: $color_black;
        }

        &--in-range {
          background-color: $color_primary_red_light !important;
          color: $color_black;
        }

        &.react-datepicker__day--disabled,
        &.react-datepicker__day--outside-month {
          color: $primary_button_disable_color;
        }

        &.react-datepicker__day--today {
          color: $color_primary !important;
        }

        &:hover {
          background-color: $color_primary_red_light;
          &:empty {
            background-color: transparent;
          }
        }
      }

      &__day--today {
        font-weight: normal;
        color: $color_primary;
        background-color: transparent;
        outline: none;
      }

      &__day--keyboard-selected:not(&__day--today) {
        background-color: transparent;
      }

      &__day--selected,
      &__day--in-selecting-range,
      &__day--in-range,
      &__month-text--selected,
      &--in-selecting-range,
      &__month-text--in-range,
      &__quarter-text--selected,
      &__quarter-text--in-selecting-range,
      &__quarter-text--in-range,
      &__year-text--selected,
      &__year-text--in-selecting-range,
      &__year-text--in-range {
        border-radius: 0;
        color: inherit;
        background-color: $color_primary_red_light;

        &:hover {
          border-radius: 0;
        }
      }

      &__day--selected:empty,
      &__day--in-selecting-range:empty,
      &__day--in-range:empty,
      &__month-text--selected:empty,
      &--in-selecting-range:empty,
      &__month-text--in-range:empty,
      &__quarter-text--selected:empty,
      &__quarter-text--in-selecting-range:empty,
      &__quarter-text--in-range:empty,
      &__year-text--selected:empty,
      &__year-text--in-selecting-range:empty,
      &__year-text--in-range:empty {
        background-color: transparent;
      }

      &__week {
        & .react-datepicker__day--in-selecting-range,
        & .react-datepicker__day--in-range {
          background-color: $color_primary_red_light !important;

          &.react-datepicker__day--outside-month {
            background-color: transparent !important;
          }


          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }

        & .react-datepicker__day--selecting-range-start,
        & .react-datepicker__day--range-start {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }

        & .react-datepicker__day--range-end {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }
  /* stylelint-enable */
}

.container {
  font-size: 13px;
  background-color: white;
  border: 1px solid $color_border_gray_light;
  border-radius: $border-radius;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.calendar {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.8rem;
  align-items: center;
  font-size: 13px;

  &.calendarGrid {
    grid-template-columns: auto 1fr;
  }
}

.label {
  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  input {
    min-width: 215px;
    font-size: 16px;
    font-family: "Etelka", Arial, sans-serif;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
  }

  button {
    svg {
      width: 10px;
      height: 10px;
    }
  }

  svg {
    width: 18px;
    height: 20px;
    margin-top: -2px;
  }
}

.apply {
  grid-column: 1 / -1;
  padding: 0.5rem;

  & button {
    height: 1.9rem;
  }
}
