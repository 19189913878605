@import "assets/styles/variables.scss";

.expand-button {
  padding: 0 1.7rem;
  cursor: pointer;
  outline: none;

  &.active:before {
    content: "";
    position: absolute;
    width: 100%;
    display: block;
    left: 0;
    z-index: 9;
    top: 4.9rem;
    background-color: $color_secondary_background_light;
    box-shadow: $shadow_block;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    line-height: 3.6rem;
  }

  &__title {
    &--opened {
      color: $color_primary;
    }

    &--active {
      position: relative;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $color_primary;
        z-index: 1;
      }
    }
  }

  &__title-icon {
    display: flex;
    width: auto;
    max-width: 2rem;
    height: 100%;
    margin-left: 0.6rem;
    margin-bottom: 0.3rem;
    transition: all 0.3s;

    & path {
      fill: $color_secondary_gray;
    }

    &--opened {
      transform: rotate(180deg);

      & path {
        fill: $color_primary;
      }
    }

    svg {
      max-width: 1.2rem;
    }
  }

  &__expand-section {
    position: absolute;
    top: 100%;
    display: block;
    z-index: 13;
    opacity: 0;
    pointer-events: none;
    height: max-content;

    &--isOpen {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__expand-section-wrapper {
    padding: 3rem 0 4.5rem;
  }

  &__navigation-column {
    position: relative;
    flex-shrink: 0;
    display: inline-block;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px;
      background-color: $color_secondary_gray_light;
      opacity: 0.5;
    }

    &:first-child {
      margin: 0;

      &:after {
        display: none;
      }
    }
  }

  .expand-button__expand-section--visible {
    opacity: 1;
    pointer-events: auto;
  }

  &:hover {
    & path {
      fill: $color_primary;
    }
  }
}

.links-block {
  margin-top: 3.5rem;

  &:first-child {
    margin-top: 0;
  }

  &__title {
    font-size: 1.4rem;
    line-height: 4.5rem;
    color: $color_secondary_gray;
    text-transform: uppercase;
    opacity: 0.7;
  }

  &__links-list {
    text-align: left;
  }

  &__button {
    border: none;
    background-color: $color_background_light;
    cursor: pointer;
    margin: 0;
    padding: 0.7rem 0;
    font-family: inherit;
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: $color_secondary_text;
    outline: none;

    &:hover:not(&--active) {
      text-decoration: underline;
    }
  }

  &__link {
    display: inline-block;
    padding: 0.7rem 0;
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: $color_secondary_text;
    text-decoration: none;
    text-transform: capitalize;
    outline: none;

    &:hover:not(&--active) {
      text-decoration: underline;
    }

    &--active {
      position: relative;
      cursor: default;
      pointer-events: none;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $color_primary;
        z-index: 1;

      }
    }
  }
}
