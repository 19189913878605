@import "assets/styles/variables.scss";

.rangeFilter {
  ul {
    display: flex;
    gap: 2rem;

    li {
      width: max-content;
      font-size: 16px;
      padding-bottom: 5px;

      button {
        text-decoration: none;

        &:hover {
          color: $color-primary;
        }
      }

      &.active {
        border-bottom: 1px solid $color-primary;
        & > button {
          color: $color-primary;
        }
      }

      &:not(:last-child) {
        line-height: 1.359;
      }

      &:not(.active):last-child {
        border-bottom: 1px solid $color_border_gray_light;
      }
    }
  }
}


