@import 'assets/styles/variables.scss';

.select {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: inherit;
  color: $color_white;

  .filter-select {
    width: 100%;
    height: 100%;

    &__menu {
      top: calc(100% + 1px);
      width: auto;
      min-width: 16rem;
      margin: 0;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 11;

      &::-webkit-scrollbar {
        position: absolute;
        width: 5px;

        &-track {
          background-color: $color_secondary_background_light;
          box-shadow: none;
        }

        &-thumb {
          min-height: auto;
          border-radius: 5px;
          background-color: darken($color_secondary_background_light, 10%);
        }
      }
    }

    &__menu-list {
      padding: 0;

      &::-webkit-scrollbar {
        position: absolute;
        width: 0.5rem;

        &-track {
          background-color: $color_secondary_background_light;
          box-shadow: none;
        }

        &-thumb {
          min-height: auto;
          border-radius: 5px;
          background-color: darken($color_secondary_background_light, 10%);
        }
      }
    }

    &__option {
      position: relative;
      width: 100%;
      padding: 0 2.5rem;
      border-bottom: 1px solid rgba(85, 86, 90, 0.2);
      font-size: 1.4rem;
      line-height: 4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color_secondary_text;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &--is-focused {
        background-color: $color_main_background_0_4;

        &:active {
          background-color: $color_main_background_0_4
        }
      }

      &--is-selected {
        background-color: $color_main_background_0_4;
        color: $color_secondary_gray_dark;
      }
    }

    &__control {
      height: 100%;
      min-height: auto;
      border: none;
      border-radius: 0;
      background-color: transparent;

      &--is-focused {
        outline-offset: 0;
        box-shadow: none;
      }

      &--menu-is-open {
        .filter-select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }

    &__dropdown-indicator {
      padding: 0;
      transition: all 0.3s;
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      padding-left: 0.2rem;
      padding-top: 0.2rem;
    }

    &__value-container {
      flex-wrap: nowrap;
      padding: 0.4rem 0 0.4rem 0.4rem;
    }

    &__single-value,
    &__multi-value {
      position: relative;
      margin: 0.4rem 0.8rem 0 0;
      padding: 0;
      color: inherit;
      background-color: transparent;
      transform: inherit;

      & + div:last-of-type {
        margin: 0;
      }

      &__label {
        padding: 0;
        font-size: 1.6rem;
        font-family: "Etelka", Arial, sans-serif;
        font-weight: inherit;
        line-height: 2.4rem;
        letter-spacing: 0.15px;
      }
    }

    &__single-value {
      margin-top: 0.4rem;
    }
  }

  &:not(.select--with-chips) {
    .filter-select {
      &__multi-value {
        color: inherit;

        &__remove {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          cursor: pointer;
          outline: none;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            display: block;
            width: 100%;
            height: 0.1rem;
            background-color: $color-primary;
            transform: translateY(-0.1rem);
            opacity: 0;
            transition: opacity 0.3s;
          }

          &:active,
          &:hover {
            background-color: transparent;

            &::after {
              opacity: 1;
            }
          }

          & svg {
            display: none;
          }
        }

        &:not(:nth-last-child(2))::after {
          content: ';';
          font-size: 1.5rem;
          font-family: $font-main;
          font-weight: inherit;
          line-height: 2.4rem;
          letter-spacing: 0.15px;
          color: inherit;
        }
      }
    }
  }
  &--with-chips {
    .filter-select {
      &__multi-value {
        position: relative;
        flex-shrink: 0;
        max-width: 100%;
        margin: 0 4px 0 0;
        padding: 0 1rem;
        border-radius: 4px;
        font-size: 1.3rem;
        line-height: 2.4rem;
        font-weight: 400;
        white-space: nowrap;
        color: $color_white;
        background-color: $color_primary;
        transition: all 0.3s;

        &__label {
          margin: 0;
          padding: 0;
          font: inherit;
          color: inherit;
        }

        &__remove {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          transition: all 0.3s;
          opacity: 0;
          cursor: pointer;

          &:hover {
            background-color: transparent;
            color: inherit;
          }
        }

        &:hover {
          padding: 0 1.8rem 0 0.2rem;

          .filter-select__multi-value__remove {
            opacity: 1;
          }
        }
       }
    }
  }

  &--with-line {
    .filter-select {
      &__value-container {
        flex-wrap: wrap;
        padding: 0;
      }

      &__single-value,
      &__multi-value {
        margin-top: 0;
      }

      &__indicators {
        align-items: flex-start !important;
        margin-top: 0.3rem;
      }

      &__placeholder {
        font-size: 1.6rem;
      }
    }
  }

  &--is-multiple {
    .filter-select {
      &__button {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: calc(100% - 1.8rem);
        padding: 0.4rem 2.4rem;
        transform: translate(-50%, -0.8rem);
      }

      &__menu {
        padding-bottom: 4.8rem;

        &::before {
          position: absolute;
          left: 0;
          bottom: 4.8rem;
          content: '';
          width: 100%;
          height: 0.1rem;
          display: block;
          background-color: $textarea_border;
        }
      }

      &__option {
        padding: 0 2.5rem 0 4rem;
        border-bottom: 1px solid $textarea_border;

        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          content: '';
          width: 1.8rem;
          height: 1.8rem;
          display: block;
          background-image: url('../../assets/img/icon-checkbox.svg');
          background-size: 100%;
          background-repeat: no-repeat;
          transform: translate(1.1rem, -50%);
        }

        &--is-selected {
          &::before {
            background-image: url('../../assets/img/icon-checkbox-checked.svg');
          }
        }

        &--all {
          width: 100%;
          text-align: left;
          border: none;
          border-bottom: 1px solid $textarea_border;
          background: transparent;
          outline: none;

          &:active,
          &:hover,
          &--is-selected {
            background-color: $color_secondary_background_light;
          }
        }
      }
    }
  }
}
