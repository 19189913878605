@import 'assets/styles/variables.scss';

.table {
  & tr {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    td {
      &:nth-last-child(-n+3) {
        & > p:not(.active),
        & > div:not(.active) {
          height: 0;
          padding: 0;
          overflow: hidden;
        }
      }

      &:last-child {
        grid-column: span 5;
      }
    }
  }

  & th,
  & td {
    font-size: 1.3rem;

    & > p {
      display: flex;
      align-items: center;
      gap: 5px;

      & svg {
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }
  }

  & th div {
    padding: 1.6rem;
    line-height: 1.231;

    @media screen and (max-width: 1440px) {
      padding: 1.1rem;
      line-height: 1.4;
    }
  }

  & tr td {
    padding: 0;

    & p {
      padding: 1.6rem;
      line-height: 1.231;
      word-break: break-word;

      @media screen and (max-width: 1440px) {
        padding: 1.1rem;
        line-height: 1.4;
      }

      &.date {
        display: block;

        & span {
          color: $color_primary;
          font-weight: 500;
        }
      }

      &.active {
        display: block;
        & p {
          padding: 0;
          margin-bottom: 1rem;
        }

        & strong {
          font-weight: 600;
        }

        & em {
          font-style: italic;
        }

        & li {
          margin: 0 1.3rem;
        }

        & ul,
        & ol {
          margin: 1rem 0;
        }

        & ul li {
          list-style-type: disc;
        }

        & ol li {
          list-style-type: decimal;
        }
      }
    }

    & .rating {
      padding: 1.2rem;
      line-height: 0;
      transform: translate(0px, -3px);

      & svg {
        width: 2.2rem;
        height: 2.2rem;
      }
    }

    & button {
      padding: 1.6rem;
      line-height: 1.231;

      & svg {
        path {
          fill: $color_secondary_gray;
        }
      }

      &.active {
        & svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
